import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../auth.service'

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthService) {}

    canActivate() {
		console.log(this.auth.isAuthenticated());
        if (this.auth.isAuthenticated()) {
            return true;
        }
        this.router.navigate(['/login']);
        return this.auth.isAuthenticated();
    }
}
