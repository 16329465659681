import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { tap, catchError, map, finalize } from 'rxjs/operators';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
const TOKEN_KEY = 'access_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url = environment.url;
  user = null;
  userData = null;
  authenticationState = new BehaviorSubject(false);
  currentUser:Object;

  constructor(private http: HttpClient, private helper: JwtHelperService, private router:Router) { }
	
	
  errorHandler(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error 
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
   }
   
   getAction(u){
    return this.http.get(`${this.url}${u}`).pipe(catchError(this.errorHandler));
  }

  deleteAction(u){
    return this.http.delete(`${this.url}${u}`).pipe(catchError(this.errorHandler));
  }
   
   postAction(u, p){
     return this.http.post(`${this.url}${u}`, p).pipe(catchError(this.errorHandler));
   }
   
   putAction(u, p){
     return this.http.put(`${this.url}${u}`, p).pipe(catchError(this.errorHandler));
   }
    
  register(credentials) {
    console.log(credentials);    
    return this.http.post(`${this.url}/api/register`, credentials).pipe(
      catchError(e => {
        console.log(e);        
        //this.showAlert('Error', e.error.msg, false);
        throw new Error(e);
      })
    );
  }
 
  login(p) {
    return this.http.post(`${this.url}/api/login`, p)
      .pipe(
        tap(res => {
			console.log(res);
			this.user = this.helper.decodeToken(res['token']);
			localStorage.setItem(TOKEN_KEY, res['token']);
			localStorage.setItem('user', JSON.stringify(this.user));
			this.postAction("/api/crud/list/smemployee", {uid:this.user.id}).subscribe(d => {
				if(d["length"]>0){
					let md = d[0];
					delete md["password"];
					this.userData=md;
					localStorage.setItem('userData', JSON.stringify(md));
					this.authenticationState.next(true);
					this.router.navigate(['/user']);
				}
			});
        }),
        catchError(e => {
         // this.showAlert('Error', e.error.msg, false);
			console.log(e);
          throw new Error(e);
        })
      );
  }

  updatePassword(p) {
    return this.http.post(`${this.url}/api/changePassword`, p).pipe(
      catchError(e => {
        console.log(e);        
        //this.showAlert('Error', e.error.msg, false);
        throw new Error(e);
      })
    );
  }

  logout() {
	  localStorage.removeItem('user');
	  localStorage.removeItem(TOKEN_KEY);
      this.authenticationState.next(false);
	  this.router.navigate(['/login']);
  }
	
isAuthenticated() {
    this.user = JSON.parse(localStorage.getItem('user'));
	this.userData = JSON.parse(localStorage.getItem('userData'));
        return this.user !== null;
  }
 
	isLoggedIn(): boolean {
        this.user = JSON.parse(localStorage.getItem('user'));
		this.userData = JSON.parse(localStorage.getItem('userData'));
        return this.user !== null;
    }
}
