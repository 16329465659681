import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms'; 
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { JwtModule } from '@auth0/angular-jwt';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

export function tokenGetter() {
  return localStorage.getItem("access_token");
}


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
        HttpClientModule,
		JwtModule.forRoot({
		  config: {
			tokenGetter: tokenGetter,
			allowedDomains: ['localhost','screenmo.run-ap-south1.goorm.io', 'kcss.in', 'kcsm.kcss.in'],
		  },
		}),
        LanguageTranslationModule,
        AppRoutingModule,
        FormlyModule.forRoot({ extras: { lazyRender: true } }),
        FormlyBootstrapModule
    ],
    declarations: [AppComponent],
    providers: [AuthGuard],
    bootstrap: [AppComponent]
})
export class AppModule {}
